import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./redux/utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./redux/actions/authActions";
import {
  getNewRequestsCount,
  setMenuCounters,
} from "./redux/actions/adminActions";
import { Provider } from "react-redux";
import store from "./redux/store";
import Layout from "./hoc/Layout/Layout";
import AdminRoute from "./components/AdminRoute/AdminRoute";
import AdminDashboard from "./containers/Admin/Dashboard/Dashboard";
import AdminUsers from "./containers/Admin/Users/UserList/UserList";
import AdminSettings from "./containers/Admin/Settings/Settings";
import TradingAccounts from "./containers/Admin/TradingAccounts/TradingAccounts";
import IbCodes from "./containers/Admin/IbCodes/IbCodes";
import Login from "./containers/Auth/Login/Login";
import VerifyLogin from "./containers/Auth/Login/VerifyLogin";
import ConfirmEmail from "./containers/Auth/ConfirmEmail/ConfirmEmail";
import ForgotPassword from "./containers/Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "./containers/Auth/ResetPassword/ResetPassword";

import "./App.css";

// PLUGINS
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-notifications/lib/notifications.css";

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "/login";
  }
}

class App extends Component {
  state = {
    newRequests: {
      newClients: 0,
    },
  };

  triggerReadNewRequests = () => {
    if (localStorage.jwtToken) {
      getNewRequestsCount(jwt_decode(localStorage.jwtToken).agentCode)
        .then(res => {
          const newRequests = res.data;
          if (
            JSON.stringify(this.state.newRequests) !==
            JSON.stringify(newRequests)
          ) {
            const newState = {
              newRequests,
            };

            store.dispatch(setMenuCounters(newRequests));
            this.setState(newState);
          }

          setTimeout(this.triggerReadNewRequests, 10000);
        })
        .catch(err => console.log(err));
    }
  };

  componentDidMount() {
    this.triggerReadNewRequests();
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <Layout>
            <Route path="/login" component={Login} />
            <Route path="/confirm-login" component={VerifyLogin} />
            <Route path="/forgot-password" component={ForgotPassword} />

            <Switch>
              {/* ADMIN ROUTES */}
              <AdminRoute exact path="/" component={AdminDashboard} />
              <AdminRoute exact path="/users/:status" component={AdminUsers} />
              <AdminRoute
                exact
                path="/settings/email-notifications"
                component={AdminSettings}
              />
              <AdminRoute
                exact
                path="/trading-accounts"
                component={TradingAccounts}
              />
              <AdminRoute exact path="/ib-links" component={IbCodes} />
            </Switch>

            <Route path="/email/confirm/:id+" component={ConfirmEmail} />
            <Route
              path="/email/confirm/:setpassword/:id+"
              component={ConfirmEmail}
            />
            <Route path="/resetpassword/:id+" component={ResetPassword} />
          </Layout>
        </Router>
      </Provider>
    );
  }
}

export default App;
